<template>
  <div v-if="selectedOrder != null">
    <b-modal
      id="order-modal"
      size="xl"
      hide-footer
      hide-header
      :visible="isOrderHandlerModalActive"
      @change="(val) => $emit('update:is-event-handler-modal-active', val)"
    >
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center">
        <b-card>
          <h5 class="mb-0">
            {{ selectedOrder.code }}
          </h5>
        </b-card>
        <div>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="$emit('closeModal', true)"
          />
        </div>
      </div>
      <b-table-lite
        responsive
        :items="orderProducts()"
        :fields="fields"
        class="rows-responsive"
      >
        <template #cell(code)="data">
          <b-card-text class="font-weight-bold mb-25">
            <b-link
              class="font-weight-bold"
              @click="viewProduct(data.item.id_product)"
            >
              {{ data.item.code }}
            </b-link>
          </b-card-text>
        </template>

        <!-- Column: Provider -->
        <template #cell(provider)="data">
          <b-card-text class="font-weight-bold mb-25">
            <b-link
              v-b-modal.modal-change-provider
              class="mr-1"
              @click="editProvider(data.item.provider_id, data.item.id_product)"
            >
              <feather-icon
                icon="ShuffleIcon"
                size="16"
              />
            </b-link>{{ data.item.provider }}
          </b-card-text>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            v-if="data.item.received < data.item.units"
            pill
            :variant="status[0].variant"
          >
            {{ status[0].status }}
          </b-badge>
          <b-badge
            v-if="data.item.received >= data.item.units"
            pill
            :variant="status[1].variant"
          >
            {{ status[1].status }}
          </b-badge>
        </template>

        <!-- Column: Actions @click="editProduct(data.item.id_product)" -->
        <template #cell(actions)="data">
          <b-row v-if="data.item.received < data.item.units">
            <!-- Cambiar de !== a === en Pending para no mostrar el botón de notas. -->
            <b-button
              v-if="orderStatus === 'PENDING'"
              :id="`notes-product-${data.item.id_product}`"
              variant="info"
              class="btn-icon mrbt"
              @click="noteProvider(data.item.code, data.item.id_product, data.item.notes)"
            >
              <feather-icon
                icon="MessageCircleIcon"
                size="16"
              />
            </b-button>
            <b-button
              :id="`edit-product-${data.item.id_product}`"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="btn-icon mrbt"
              @click="editStockProduct(data.item.code, data.item.id_product, data.item.mechanic, data.item.provider, data.item.units)"
            >
              <feather-icon
                icon="TruckIcon"
                size="16"
              />
            </b-button>
            <b-button
              :id="`delete-product-${data.item.id_product}`"
              variant="danger"
              class="btn-icon"
              @click="confirmDeleteProduct(data.item.id_product, orderId)"
            >
              <feather-icon
                icon="TrashIcon"
                size="16"
              />
            </b-button>
          </b-row>
        </template>
      </b-table-lite>
      <b-row
        align-h="end"
        class="pr-1"
      >
        <!-- <b-button
          v-if="orderStatus === 'RECEIVED'"
          variant="primary"
          @click="confirmCloseOrder"
        >
          {{ $t("orders.modal.close_order") }}
        </b-button> -->
        <b-button
          variant="primary"
          @click="closeModal"
        >
          {{ $t("orders.modal.close") }}
        </b-button>
      </b-row>
    </b-modal>
    <b-modal
      id="modal-edit-stock"
      cancel-variant="outline-secondary"
      :ok-title="$t('orders.modal.save')"
      :cancel-title="$t('common.cancel')"
      centered
      :title="
        $t('toast.title.new_stock') +
          ' - ' +
          $t('provider.product') +
          ': ' +
          product_code
      "
      :visible="showEditStock"
      @ok="
        () => {
          updateOrder(selectedOrder.status, product_id);
        }
      "
    >
      <b-form>
        <b-form-group
          :label="$t('user.mechanic')"
          label-for="item-name"
        >
          <b-form-input
            v-model="mechanic.name"
            value-field="id"
            text-field="name"
            readonly
          />
        </b-form-group>
        <b-row>
          <b-col>
            <b-form-group
              :label="$t('stocks.units_required')"
              label-for="quantity"
            >
              <b-form-input
                id="units-required"
                v-model="StockUnits"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col cols="8">
            <b-form-group
              :label="$t('stocks.units_received')"
              label-for="units-received"
            >
              <b-form-input
                id="units-received"
                v-model="UnitsReceived"
                type="number"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <b-modal
      id="modal-edit-note"
      cancel-variant="outline-secondary"
      :ok-title="$t('orders.modal.save')"
      :cancel-title="$t('common.cancel')"
      centered
      :title="
        $t('orders.modal.description') +
          ' - ' +
          $t('provider.product') +
          ': ' +
          product_code"
      :visible="showEditNote"
      @ok="
        () => {
          updateOrder(selectedOrder.status, product_id);
        }
      "
    >
      <b-form>
        <b-form-textarea
          v-model="noteToProvider"
          :placeholder="$t('orders.modal.note_to_provider')"
          value-field="id"
          text-field="name"
        />
      </b-form>
    </b-modal>
    <b-modal
      id="modal-change-provider"
      cancel-variant="outline-secondary"
      :ok-title="$t('orders.modal.save')"
      :cancel-title="$t('common.cancel')"
      centered
      :title="$t('orders.modal.change_provider')"
      @ok="
        () => {
          changeProvider(orderId, product_id, provider.new_provider)
        }
      "
    >
      <b-form>
        <b-form-group
          :label="$t('orders.modal.old_provider')"
        >
          <b-form-input
            v-model="provider.name"
            readonly
          />
        </b-form-group>
        <b-form-group
                :label="$t('orders.modal.new_provider')"
        >
          <b-form-select
            v-model="provider.new_provider"
            :options="optProviders"
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import {
  BModal,
  BForm,
  BFormInput,
  BFormTextarea,
  BRow,
  BTableLite,
  BCardText,
  BLink,
  BBadge,
  BFormGroup,
  BButton,
  BFormSelect,
  BCol,
  BCard,
} from 'bootstrap-vue'
import dbOrder from '@/models/orders'

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BRow,
    BLink,
    BTableLite,
    BCardText,
    BBadge,
    BButton,
    BFormSelect,
    BCol,
    BCard,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isOrderHandlerModalActive',
    event: 'update:is-event-handler-modal-active',
  },
  props: {
    isOrderHandlerModalActive: {
      type: Boolean,
      required: true,
    },
    selectedOrder: {
      type: Object,
      default: () => {},
    },
    orderId: {
      type: Number,
      default: 0,
    },
    orderStatus: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      status: [
        { status: this.$i18n.t('orders.table.pending'), variant: 'primary' },
        { status: this.$i18n.t('orders.table.delivered'), variant: 'success' },
      ],
      fields: [
        { key: 'code', label: this.$i18n.t('orders.table.code') },
        { key: 'name', label: this.$i18n.t('budget.name') },
        { key: 'units', label: this.$i18n.t('stocks.units') },
        { key: 'received', label: this.$i18n.t('orders.table.received') },
        { key: 'provider', label: this.$i18n.t('orders.table.providers') },
        { key: 'email', label: 'Email' },
        { key: 'status', label: this.$i18n.t('orders.table.status') },
        { key: 'actions', label: this.$i18n.t('orders.table.actions'), class: 'actions-bt' },
      ],
      resetTable: true,
      showEditStock: false,
      showEditNote: false,
      product_code: '',
      mechanic: '',
      provider: '',
      optProviders: null,
      StockUnits: null,
      UnitsReceived: null,
      product_id: null,
      provider_id: null,
      noteToProvider: '',
    }
  },
  computed: {
    mechanics() {
      const optionsMechanics = []
      const arr = this.$store.state.app.system?.users_map?.MECHANICS
      const map = new Map()
      arr.forEach(obj => {
        Object.keys(obj).forEach(key => {
          map.set(key, obj[key])
        })
      })

      map.forEach((val, key) => {
        optionsMechanics.push({
          idMechanic: key,
          name: val,
        })
      })

      return optionsMechanics
    },
    providers() {
      const optionsProviders = []
      const arr = this.$store.state.app.system.products.providers
      const map = new Map()
      Object.keys(arr).forEach(key => {
        map.set(key, arr[key])
      })
      map.forEach((val, key) => {
        optionsProviders.push({
          idProvider: key,
          name: val,
        })
      })

      return optionsProviders
    },
  },
  methods: {
    getMechanic(id) {
      return this.mechanics.find(element => element.idMechanic === `${id}`)
    },
    getProvider(id) {
      return this.providers.find(element => element.idProvider === `${id}`)
    },
    async viewProduct(idProduct) {
      this.$router.push({
        name: 'tab-product-stock',
        params: { id: idProduct },
      })
    },
    /* confirmCloseOrder() {
      this.$bvModal
        .msgBoxConfirm(this.$t('orders.modal.confirm_close_order_body'), {
          title: this.$t('orders.modal.confirm_close_order_title'),
          size: 'sm',
          okVariant: 'success',
          okTitle: this.$t('orders.modal.yes'),
          cancelTitle: this.$t('orders.modal.no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) this.updateOrder('RECEIVED')
        })
    }, */
    closeModal() {
      this.$bvModal.hide('order-modal')
    },
    confirmDeleteProduct(productId, OrderId) {
      this.$bvModal
        .msgBoxConfirm(this.$t('orders.confim_delete_product.body'), {
          title: this.$t('orders.confim_delete_product.title'),
          size: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('budget.modal.Yes'),
          cancelTitle: this.$t('budget.modal.No'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) this.deleteProduct(productId, OrderId)
        })
    },
    deleteProduct(productId, OrderId) {
      try {
        dbOrder.deleteProduct(productId, OrderId)
        this.$emit('resetModal', OrderId)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `${this.$i18n.t('toast.title.delete_product_order')}`,
            icon: 'XIcon',
            variant: 'danger',
            text: '',
          },
        })
      }
    },
    async updateOrder(stat, productId) {
      try {
        const prodArray = this.orderProducts()
        let prodObj = {}

        prodArray.forEach(element => {
          if (productId != null) {
            // eslint-disable-next-line
            if (productId == element.id_product) {
              const valueReceived = this.UnitsReceived !== null ? this.UnitsReceived : 0
              prodObj[`${element.code}`] = {
                id_product: element.id_product,
                product_code: element.code,
                units: element.units,
                received: valueReceived,
                mechanic: this.mechanic,
                note_provider: this.noteToProvider,
              }
            }
          }
        })
        // eslint-disable-next-line
        if (stat == 'RECEIVED') {
          prodObj = {}
        }

        const order = {
          budget_code: this.selectedOrder.budget[0].code,
          products: prodObj,
          orderId: this.orderId,
          notes: this.selectedOrder.notes,
          status: stat,
        }
        await dbOrder.save(order)
        this.$emit('resetModal', this.orderId)
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `${this.$i18n.t('toast.title.order_updated')}`,
            icon: 'CheckIcon',
            variant: 'success',
            text: '',
          },
        })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `${this.$i18n.t(
              'toast.title.send_email_to_providers_error',
            )}`,
            icon: 'XIcon',
            variant: 'danger',
            text: '',
          },
        })
      }
    },
    orderProducts() {
      const obj = this.selectedOrder.products
      const map = new Map()
      Object.keys(obj).forEach(key => {
        map.set(key, obj[key])
      })
      const array = []
      map.forEach((val, key) => {
        array.push({
          code: key,
          id_product: val.id_product,
          provider_id: val.provider_id,
          provider_city: val.provider_city,
          provider: val.provider,
          email: val.email,
          name: val.name,
          units: val.units,
          stock: val.stock,
          received: val.received,
          mechanic: val.mechanic,
          notes: val.notes,
        })
      })

      return array
    },
    editStockProduct(ProductCode, ProductId, mechanic, provider, units) {
      this.showEditStock = !this.showEditStock
      this.product_code = ProductCode
      this.product_id = ProductId
      this.StockUnits = units

      if (mechanic != null) {
        this.mechanic = this.getMechanic(mechanic)
      } else {
        this.mechanic = {
          name: provider,
          id: null,
        }
      }
    },
    noteProvider(ProductCode, ProductId, notes) {
      this.showEditNote = !this.showEditNote
      this.product_code = ProductCode
      this.product_id = ProductId
      this.noteToProvider = notes
    },
    editProvider(providerId, productId) {
      this.provider = this.getProvider(providerId)
      this.optProviders = this.$store.state.app.system.products.providers
      this.product_id = productId
    },
    async changeProvider(idOrder, ProductId, ProviderId) {
      await dbOrder.changeProvider({ idOrder, ProductId, ProviderId })
      this.$emit('resetModal', idOrder)
    },
  },
}
</script>
<style>
.actions-bt {
  min-width: 160px;
}
</style>
