<template>
  <!-- Table Container Card -->
  <b-overlay :show="loadingOrder">
    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
              @change="updatePerPage"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="search"
                class="d-inline-block mr-1"
                :placeholder="$t('common.search')"
                @keyup="searchOrders"
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <order-modal
        v-model="isOrderHandlerModalActive"
        :is-order-handler-modal-active="isOrderHandlerModalActive"
        :selected-order="selectedOrder"
        :order-id="currentOrderId"
        :order-status="currentOrderStatus"
        @closeModal="isOrderHandlerModalActive = false"
        @resetModal="resetModal(currentOrderId)"
      />

      <b-table
        ref="ordersTable"
        :items="filterOrders"
        responsive
        :fields="headers"
        primary-key="invoice_id"
        :sort-by.sync="sortBy"
        :per-page="0"
        show-empty
        :empty-text="$t('invoice.no_result')"
        :sort-desc.sync="isSortDirDesc"
        :busy="isBusy"
        :current-page="currentPage"
        class="position-relative rows-responsive"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <!-- Column: Id -->
        <template #head(code)>
          <div class="mx-auto">
            {{ $t('menu.orders') }}
          </div>
        </template>

        <!-- Column: Order Customer -->
        <template #cell(customer)="data">
          <b-link
            class="font-weight-bold"
            :to="{
              name: 'new-customer',
              params: { id: data.item.idcustomer },
            }"
          >
            {{ data.item.customer }}
          </b-link>
        </template>

        <!-- Column: Order Code -->
        <template #cell(code)="data">
          <b-link
            class="font-weight-bold"
            @click="editOrder(data.item.id)"
          >
            #{{ data.item.code }}
          </b-link>
        </template>

        <template #cell(budget)="data">
          <b-link
            :to="{
              name: 'drs-budgets-form',
              params: { id: data.item.budget[0].id },
            }"
            class="font-weight-bold"
          >
            #{{ data.item.budget[0].code }}
          </b-link>
        </template>

        <!-- Column: date -->
        <template #cell(created)="data">
          <span>{{ formatDate(data.item.created_at) }}</span>
        </template>
        <template #cell(updated)="data">
          <span>{{ formatDate(data.item.updated_at) }}</span>
        </template>

        <!-- Column: Order Status -->
        <template #cell(status)="data">
          <b-avatar
                  :id="`status-row-${data.item.id}`"
                  size="32"
                  :variant="`light-${
              resolveOrdersStatusVariantAndIcon(data.item.status).variant
            }`"
          >
            <feather-icon
                    :icon="resolveOrdersStatusVariantAndIcon(data.item.status).icon"
            />
          </b-avatar>
          <b-tooltip
                  :target="`status-row-${data.item.id}`"
                  placement="top"
          >
            <p class="mb-0">
              {{ data.item.status }}
            </p>
            <p class="mb-0">
              {{ $t('orders.table.tooltip_due_date') }} {{ data.item.updated_at.substring(0,10) }}
            </p>
          </b-tooltip>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            v-if="data.item.status != 'CANCELLED' && data.item.status != 'RECEIVED' && data.item.providers_email_sent !== 1"
            :id="`send-email-provider-${data.item.id}`"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="info"
            class="btn-icon mrbt"
            @click="confirmSendEmailToProviders(data.item)"
          >
            <feather-icon
              icon="MailIcon"
              size="16"
            />
          </b-button>
          <b-button
            v-if="data.item.status != 'CANCELLED' && data.item.status != 'RECEIVED' && data.item.providers_email_sent === 1"
            :id="`send-email-provider-${data.item.id}`"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon mrbt"
            @click="confirmSendEmailToProviders(data.item)"
          >
            <feather-icon
              icon="ArrowUpRightIcon"
              size="16"
            />
          </b-button>
          <b-button
            v-if="data.item.status != 'CANCELLED' && data.item.status != 'RECEIVED'"
            :id="`edit-order-${data.item.id}`"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="btn-icon mrbt"
            @click="editOrder(data.item.id)"
          >
            <feather-icon
              icon="EditIcon"
              size="16"
            />
          </b-button>
          <b-button
            v-else
            :id="`edit-order-${data.item.id}`"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="info"
            class="btn-icon mrbt"
            @click="editOrder(data.item.id)"
          >
            <feather-icon
                    icon="EyeIcon"
                    size="16"
            />
          </b-button>
          <b-button
            v-if="data.item.status != 'CANCELLED' && data.item.status != 'RECEIVED'"
            :id="`cancel-order-${data.item.id}`"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            class="btn-icon"
            @click="cancelOrder(data.item.id)"
          >
            <feather-icon
              icon="XCircleIcon"
              size="16"
            />
          </b-button>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
            d-flex
            align-items-center
            justify-content-center justify-content-sm-start
          "
          >
            <span
              class="text-muted"
            >{{ $t("common.displaying") }}
              {{
                perPage * currentPage > totalItems
                  ? totalItems
                  : perPage * currentPage
              }}
              {{ $t("common.of") }} {{ totalItems }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
            d-flex
            align-items-center
            justify-content-center justify-content-sm-end
          "
          >
            <b-pagination
              v-if="renderPagination"
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BAvatar,
  BLink,
  BPagination,
  BTooltip,
  BSpinner,
  BOverlay,
  BButton,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import dbOrders from '@/models/orders'
import useInvoicesList from './useInvoicesList'
import OrderModal from './OrderModal.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BAvatar,
    BLink,
    BPagination,
    BTooltip,
    BSpinner,
    vSelect,
    OrderModal,
    BOverlay,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      renderPagination: true,
      currentOrderId: null,
      currentOrderStatus: '',
      selectedOrder: null,
      loadingOrder: false,
      orders: [],
      search: '',
      status: '',
      isBusy: false,
      perPage: 50,
      pageOptions: [25, 50, 100, 500],
      totalItems: null,
      headers: [],
      fields: [
        { key: 'status', label: 'status', sortable: true },
        { key: 'code', label: 'menu.orders', sortable: true },
        { key: 'customer', sortable: true },
        { key: 'budget', label: 'menu.budget', sortable: true },
        { key: 'created', label: 'orders.table.created', sortable: true },
        { key: 'updated', label: 'orders.table.updated', sortable: true },
        { key: 'actions', label: 'common.actions' },
      ],
      isOrderHandlerModalActive: false,
    }
  },
  computed: {
    filterOrders() {
      return this.orders.filter(
        i => i.id.toString().toLowerCase().includes(this.search.toLowerCase())
          || i.customer.toLowerCase().includes(this.search.toLowerCase())
          || i.budget[0].code.toLowerCase().includes(this.search.toLowerCase())
          || i.code.toLowerCase().includes(this.search.toLowerCase()),
      )
    },
    filterInvoicesByStatus() {
      return this.orders.filter(i => i.status.toLowerCase().includes(this.status.toLowerCase()))
    },
  },
  watch: {
    currentPage() {
      this.fetch(this.currentPage)
    },
  },
  async created() {
    window.addEventListener('resize', this.initTrHeight)
    this.fetch(1)
  },
  beforeUpdate() {
    this.fieldLanguage()
  },
  mounted() {
    this.fieldLanguage()
  },
  methods: {
    searchOrders() {
      if (this.search.length > 2 || this.search.length === 0) this.fetch(1)
    },
    formatDate(value) {
      return moment(value).format('DD-MM-YYYY')
    },
    async editOrder(id) {
      this.currentOrderId = id
      this.loadingOrder = true
      this.selectedOrder = await dbOrders.get(id)
      this.currentOrderStatus = this.selectedOrder.status
      this.loadingOrder = false
      this.isOrderHandlerModalActive = !this.isOrderHandlerModalActive
    },
    async resetModal(id) {
      await this.editOrder(id)
      this.isOrderHandlerModalActive = !this.isOrderHandlerModalActive
      this.fetch(1)
    },
    async cancelOrder(id) {
      this.$bvModal.msgBoxConfirm(this.$t('orders.confim_cancel.body'), {
        title: this.$t('orders.confim_cancel.title'),
        size: 'sm',
        okVariant: 'danger',
        okTitle: this.$t('budget.modal.Yes'),
        cancelTitle: this.$t('budget.modal.No'),
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) dbOrders.cancel(id)
          this.fetch(1)
        })
    },
    confirmSendEmailToProviders(order) {
      this.$bvModal.msgBoxConfirm(this.$t('orders.confim_send_email.body'), {
        title: this.$t('orders.confim_send_email.title'),
        size: 'sm',
        okVariant: 'primary',
        okTitle: this.$t('budget.modal.Yes'),
        cancelTitle: this.$t('budget.modal.No'),
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) this.sendEmailToProviders(order)
        })
    },
    async sendEmailToProviders(order) {
      try {
        await dbOrders.sendEmail(order.id)
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `${this.$i18n.t('toast.title.send_order')}`,
            icon: 'CheckIcon',
            variant: 'success',
            text: '',
          },
        })
        this.fetch(1)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `${this.$i18n.t('toast.title.send_order_error')}`,
            icon: 'XIcon',
            variant: 'danger',
            text: '',
          },
        })
      }
    },
    fieldLanguage() {
      this.headers = JSON.parse(JSON.stringify(this.fields))
      this.fields.forEach((header, index) => {
        if (header.label) {
          this.headers[index].label = `${this.$i18n.t(
            this.fields[index].label,
          )}`
        }
      })
    },
    updatePerPage(size) {
      this.perPage = size
      this.fetch(1)
    },
    async fetch(page) {
      this.isBusy = true
      const response = await dbOrders.getAll({
        page,
        perPage: this.perPage,
        paginate: true,
        word: this.search,
      })
      this.orders = response.data
      this.totalItems = response.total
      this.isBusy = false
      this.renderPagination = false
      this.$nextTick(() => {
        this.renderPagination = true
      })
    },
  },
  setup() {
    const {
      tableColumns,
      currentPage,
      dataMeta,
      sortBy,
      isSortDirDesc,
      refOrdersListTable,
      resolveOrdersStatusVariantAndIcon,
      resolveProviderAvatarVariant,
      refetchData,
    } = useInvoicesList()

    return {
      tableColumns,
      currentPage,
      dataMeta,
      sortBy,
      isSortDirDesc,
      refOrdersListTable,
      refetchData,
      avatarText,
      resolveOrdersStatusVariantAndIcon,
      resolveProviderAvatarVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
