import api from '@/api/index'

export default {
  getAll: async payload => {
    const response = await api.orders.getAll(payload)
    const { data } = response
    return data
  },
  get: async payload => {
    const response = await api.orders.get(payload)
    const { data } = response
    return data
  },
  sendEmail: async payload => {
    const response = await api.orders.sendEmail(payload)
    return response
  },
  save: async payload => {
    const response = await api.orders.save(payload)
    return response
  },
  changeProvider: async payload => {
    const response = await api.orders.changeProvider(payload)
    return response
  },
  cancel: async payload => {
    const response = await api.orders.cancel(payload)
    return response
  },
  deleteProduct: async (payload, payload2) => {
    const response = await api.orders.deleteProduct(payload, payload2)
    return response
  },
}
